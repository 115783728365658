import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, Link} from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import $ from "jquery";
import {
  updateState,
  selectMap,
  selectDitaPath,
  selectID,
  selectVersion,
  selectVersionByImport,
  setShowAccessKey,
  fetchSubscriptionStatusThunk,
} from "../features/counter/counterSlice";
import {
  setOpenModalFlag,
  selectOpenModalFlag,
} from "../store/reducers/favourite";
import {
  selectSearchIndex,
  setSearchIndex,
  setSearchTerm,
} from "../store/reducers/search";
import FavouriteModal from "../components/favouriteModal";
import { NavLink } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import { loginRequest, useDxp } from '../authConfig';
import { isPWAInstalled, openLoginModal } from "../features/search/util";
import LoginModal from "./loginModal";
import useDebounce from "../hooks/useDebounce";
import Menu from "./navBar/menu";
import SearchDropdown from "./navBar/searchDropdown";
import { getTotalCartQuantity } from "../store/reducers/subscripion";
import useScreenSize from '../hooks/useScreenSize';
import ProgressBar from "./navBar/progressBar";
const DrugIndexModal = React.lazy(() => import("./drugIndexModal"));
const LoginButton = React.lazy(() => import("./loginButton"));
const Profile = React.lazy(() => import("./profile"));

const defiant = window.defiant;
const tgg = window.tgg;

const NavBar = (props) => {
  const inputFieldRef = useRef(null);
  const workerRef = useRef(null);
  const dropdownRef = useRef(null)
  const dropdownMobileRef = useRef(null)

  const { instance } = useMsal();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { push, setWithinSearchVal, isDXP } = props;
  const [guidelineOpenClass, setGuidelineOpenClass] = useState("");
  const [quicklinksOpenClass, setQuicklinksOpenClass] = useState("");
  const [mobileMenuClass, setMobileMenuClass] = useState("closed");
  const [searchableValues, setSearchableValues] = useState(new Map());
  const [mobileSearchClass, setMobileSearchClass] = useState("closed");
  const [faClass, setFaClass] = useState("fa-bars");
  const [searchFaClass, setSearchFaClass] = useState("fa-search");
  const openModalFlag = useSelector(selectOpenModalFlag);
  const authID = useSelector(selectID);
  const searchIndex = useSelector(selectSearchIndex);
  const routerAction = useSelector((state) => state.router.action);
  const tocMap = useSelector(selectMap);
  const ditaPath = useSelector(selectDitaPath);
  const ccmsversion = useSelector(selectVersion);
  const version = ccmsversion.selected;
  const versionByImport = useSelector(selectVersionByImport);
  const totalCartQuantity = useSelector(getTotalCartQuantity);
  const [isOpen, setIsOpen] = useState(false)
  const isWebScreen = useScreenSize();

  const styles = {
 
    container: {
      position: 'relative',
      width: '256px'
    },
    inputWrapper: {
      position: 'relative'
    },
    input: {
      width: '100%',
      padding: '8px 16px',
      paddingRight: '40px',
      border: '1px solid #e2e8f0',
      borderRadius: '8px',
      fontSize: '14px',
      outline: 'none'
    },
    inputFocused: {
      borderColor: '#3b82f6',
      boxShadow: '0 0 0 2px rgba(59, 130, 246, 0.2)'
    },
    searchIcon: {
      position: 'absolute',
      right: '12px',
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#9ca3af'
    },
  }

  const [items, setItems] = useState([])

  const handleSelect = (item) => {
    dispatch(setSearchIndex(item));
    try {
      dispatch(setSearchTerm(item));
      push(
        "/searchAction/?appendedinputbuttons=" +
        encodeURIComponent(item)
      );
      inputFieldRef.current.blur();
    } catch (error) {
      console.log(error);
    }

    setIsOpen(false)
  }

  useEffect(() => {
    if (authID.u || authID.i) {
      const email = authID?.u?.["signInNames.emailAddress"];

      dispatch(fetchSubscriptionStatusThunk(email));
    }
  }, [authID]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && dropdownMobileRef.current && !dropdownMobileRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    workerRef.current = new Worker(new URL('./search.worker.js', import.meta.url));
    workerRef.current.onerror = (error) => {
      console.error('Web Worker error:', error);
    };

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      workerRef.current?.terminate();
    }
  }, []);

  useEffect(() => {
    if (location.hash === '#login') {
      handleLoginClick();
    }
  }, [location]);

  useEffect(() => {
    $(document).on("click", "#drugIndexModal .close", function (e) {
      $("#drugIndexModal").removeClass("in");
      $("#drugIndexModal").css("display", "none");
      $(".modal-backdrop").removeClass("in");
      $(".modal-backdrop").css("display", "none");
    });

    $(document).on("click", 'a[href="#drugIndexModal"]', function (e) {
      $("#drugIndexModal").addClass("in");
      $("#drugIndexModal").css("display", "block");
      $(".modal-backdrop").addClass("in");
      $(".modal-backdrop").css("display", "block");
    });

    window.onclick = function (event) {
      if (!event.target.matches(".dropbtn")) {
        setQuicklinksOpenClass("");
        setGuidelineOpenClass("");
      }
    };

    return () => {
      $(document).off("click", "#drugIndexModal .close");
      $(document).off("click", 'a[href="#drugIndexModal"]');
    };
  }, []);

  useEffect(() => {
    // console.log(routerAction);
    if (routerAction === "PUSH") {
      // console.log(mobileMenuClass,navbarToggleClass);
      if (mobileMenuClass === "open") {
        setMobileMenuClass("closed");
        setFaClass("fa-bars");
        $(".tg-mobile-menu").slideUp();
      }

      if ($("#drugIndexModal").is(":visible")) {
        $('#drugIndexModal [data-dismiss="modal"]').trigger("click");
        const data = { value: ["chapter"] };
        dispatch(updateState(data));
      }

      $("body").removeClass("modal-open");
    }
  }, [routerAction, tocMap]);

  useEffect(() => {
    if (window.location.href.indexOf("topicfile") === -1) {
      setTimeout(() => {
        $(".slider-arrow.show").trigger("click");
      }, 3000);
    }
  }, [ditaPath]);

  const quicklinksDropdown = (e) => {
    e.preventDefault();
    if (window.Microsoft) {
      if (quicklinksOpenClass === "open") {
        setQuicklinksOpenClass("open");
      } else {
        setQuicklinksOpenClass("");
      }
    } else {
      if (quicklinksOpenClass === "open") {
        setQuicklinksOpenClass("");
      } else {
        setQuicklinksOpenClass("open");
      }
    }
  };

  const guidelinesDropdown = (e) => {
    e.preventDefault();
    if (window.Microsoft) {
      if (guidelineOpenClass === "open") {
        setGuidelineOpenClass("open");
      } else {
        setGuidelineOpenClass("");
      }
    } else {
      if (guidelineOpenClass === "open") {
        setGuidelineOpenClass("");
      } else {
        setGuidelineOpenClass("open");
      }
    }
  };

  const toggleMobileMenu = (e) => {
    try {
      e.preventDefault();
    } catch (error) { } //TODO: remove try catch if this is not required.
    if (mobileMenuClass === "closed") {
      if (document.querySelector("a.slider-arrow.show")) {
        document.querySelector("a.slider-arrow.show").click();
      } else if (document.querySelector("div.slider-arrow.hide")) {
        document.querySelector("div.slider-arrow.hide").click();
      }

      setMobileMenuClass("open");
      setFaClass("fa-times");
      $(".tg-mobile-menu").slideDown();

      setMobileSearchClass("closed");
      $(".tg-mobile-search").slideUp();
    } else {
      setMobileMenuClass("closed");
      setFaClass("fa-bars");
      $(".tg-mobile-menu").slideUp();
    }
  };

  const mobileSearchToggle = () => {
    if (mobileSearchClass === "closed") {
      setMobileSearchClass("opened");
      setSearchFaClass("fa-times");
      $(".tg-mobile-search").slideDown();

      setMobileMenuClass("closed");
      setFaClass("fa-bars");
      $(".tg-mobile-menu").slideUp();
    } else {
      setMobileSearchClass("closed");
      setSearchFaClass("fa-search");
      $(".tg-mobile-search").slideUp();
    }
  };

  const keypressSearchHandler = (event) => {
    if (event.keyCode === 13 && event.target.value.length >= 3) {
      dispatch(setSearchTerm(event.target.value));
      push(
        "/searchAction/?appendedinputbuttons=" +
        encodeURIComponent(event.target.value)
      );
      inputFieldRef.current.blur();

      if (setWithinSearchVal) {
        setWithinSearchVal("");
      }
    }
  };

  const handleSearchClick = (inputType = "desktop") => {
    let searchValue = document.getElementById("searchString").value;
    let searchValueMobile = document.getElementById("searchStringMobile").value;
    console.log(searchValue, inputType);
    if (inputType === "mobile") {
      searchValue = searchValueMobile;
    }
    if (searchValue.length >= 3) {
      console.log(searchValue);
      dispatch(setSearchTerm(searchValue));
      push(
        "/searchAction/?appendedinputbuttons=" + encodeURIComponent(searchValue)
      );
      inputFieldRef.current.blur();
    }
  };

  const keyupSearchHandler = async (e, setWithinSearchVal, isMobile) => {
    const { debounceFn } = useDebounce();
    const searchInputValue = e.target.value.toLowerCase().trim();

    if (searchInputValue.length !== 0) {
      keypressSearchHandler(e);
    }

    if (searchInputValue.length < 3) {
      setItems([]);
      return;
    }

    const performSearch = debounceFn(() => {
      if (searchInputValue.length >= 3) {
        workerRef.current.onmessage = function (e) {
          if (e.data.error) {
            console.error('Search worker error:', e.data.error);
            return;
          }
          setItems([]);

          const results = e.data.results;

          results.forEach(result => {
            let option = result
              .replace(/,|:|\(|\)|\-|\+/g, " ")
              .replace(/   /g, " ")
              .replace(/  /g, " ");
            setItems((prevItems) => [...prevItems, option]);

          });
        };

        workerRef.current.postMessage({
          searchableValues,
          searchInputValue,
          tgg,
        });
      }
    }, 800);

    performSearch();
  };

  const goBack = () => {
    history.goBack();
  };

  const openModal = (e) => {
    e.preventDefault();
    dispatch(setOpenModalFlag(true));
  };

  const closeModal = () => {
    dispatch(setOpenModalFlag(false));
  };

  const handleLoginClick = () => {
    if (useDxp) {
      if (isPWAInstalled()) {
        instance.loginRedirect(loginRequest).catch(e => {
          console.error(e);
        });
      } else {
        instance.loginPopup(loginRequest).catch(e => {
          console.error(e);
        });
      }
    } else {
      dispatch(setShowAccessKey(false));
      openLoginModal();
    }
  };

  const handleDXPLoginClick = () => {
    instance.loginRedirect(loginRequest).catch(e => {
      console.error(e);
    });
  };

  const handleAccessToken = () => {
    dispatch(setShowAccessKey(true));
    openLoginModal();
  };

  const showHeader = !(window.Microsoft || false);
  let style = { display: "none" };
  if (showHeader) {
    style = {};
  }

  const handleImportClick = () => {
    push("/import");
  };

  function buildSearchableValues(obj) {
    let searchableValues = new Map();
    const stack = [obj];
    
    while (stack.length > 0) {
      const current = stack.pop();
      
      Object.entries(current).forEach(([_, value]) => {
        if (typeof value === 'string') {
          // Split and store each term with its lowercase version for searching
          value.split(", ").forEach(term => {
            searchableValues.set(term, term.toLowerCase());
          });
        } else if (value && typeof value === 'object') {
          stack.push(value);
        }
      });
    }
    return searchableValues;
  }

  useEffect(() => {
    setSearchableValues(buildSearchableValues(tgg));
  }, [tgg]);

  return (
    <div className="navbar navbar-static-top" id="dart-nav" role="navigation">
      <div className="container container-center">
        <div className="navbar-header">
          <div className="mobile-navbar">
            <div>
              <table>
                <tbody>
                  <tr>
                    <th>
                      <a title="Back" href="#" onClick={goBack}>
                        <i
                          style={{ fontSize: "40px", color: "#5d5a58" }}
                          className="fa"
                        >
                          &#xf104;
                        </i>
                      </a>
                    </th>
                    <th>
                      <NavLink
                        to="#"
                        title="Menu"
                        className={`mobile-menu-button ${mobileMenuClass}`}
                        onClick={toggleMobileMenu}
                      >
                        <i
                          style={{ fontSize: "24px", color: "#5d5a58" }}
                          className={`fa ${faClass}`}
                        ></i>
                      </NavLink>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mobile-logo">
              <HashLink
                to="/etgAccess/"
                title="Therapeutic Guidelines"
                className="homelink"
              >
                <svg>
                  <image
                    xlinkHref="/TherapeuticGuidelines_Original_Logo.svg"
                    src="./assets/images/tglLogo.png"
                    alt="Therapeutic Guidelines"
                  ></image>
                </svg>
              </HashLink>
            </div>
            <div
              className={`mobile-search ${mobileSearchClass}`}
              onClick={() => mobileSearchToggle()}
            >
              <NavLink
                to="#"
                title="Search"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <i
                  style={{ fontSize: "24px" }}
                  className={`fa ${searchFaClass}`}
                ></i>
              </NavLink>
            </div>
          </div>
          <div className="desktop-navbar">
            <div className="visible-xs-block col-xs-9 navbar-brand-xs">
              <div
                className="xrm-editable-html xrm-attribute"
                data-languagecontext="English"
              >
                <div className="xrm-attribute-value" >
                  <p>
                    <a
                      href="/"
                      title="Therapeutic Guidelines"
                      className="homelink"
                    >
                      <svg width="127" height="39">
                        <image
                          xlinkHref="/TherapeuticGuidelines_Original_Logo.svg"
                          src="./assets/images/tglLogo.png"
                          width="127"
                          height="39"
                          alt="Therapeutic Guidelines"
                        ></image>
                      </svg>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="visible-sm-block visible-md-block visible-lg-block navbar-brand">
              <div
                className="xrm-editable-html xrm-attribute"
                data-languagecontext="English"
                style={{ position: "absolute",zIndex: 1000 }}
              >
                <div
                  className="xrm-attribute-value dart-mobile-logo"
                  style={style}
                >
                  <p>
                    <a
                      href="/"
                      title="Therapeutic Guidelines"
                      className="homelink"
                    >
                      <svg width="127" height="39">
                        <image
                          xlinkHref="/TherapeuticGuidelines_Original_Logo.svg"
                          src="./assets/images/tglLogo.png"
                          width="127"
                          height="39"
                          alt="Therapeutic Guidelines"
                        ></image>
                      </svg>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="navbar-right loginsubbutton visible-lg-block"
          style={{ paddingLeft: "0" }}
        >
          <div className="btn-group" style={{ display: "none" }}>
            <a
              href="#"
              className="btn btn-info btn-block institutionBoxIns"
              style={{ background: "#415b63", textDecoration: "underline" }}
            >
              Therapeutic Guidelines
            </a>
          </div>
        </div>
      </div>
      <div className="container tg-mobile-menu">
        {!window.Microsoft && (
          <div
            id="navbar-right-section"
          >
            <div 
            id="navbar-right-section-container"
            style={{ display: "flex", alignItems: "center"}}>
            {version && (
              <span
                className="import-indicate"
                style={{
                  display: versionByImport ? "inline" : "none",
                  textAlign: "right",
                }}
              >
                Current Version: {version} &nbsp;&nbsp;
                <span
                  className="import-indicate-text"
                  style={{
                    display: "block",
                    lineHeight: "1",
                    margin: "0",
                    padding: "0",
                    color: "#e57373",
                  }}
                >
                  After refreshing, go to{" "}
                  <button
                    onClick={handleImportClick}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      color: "#e57373",
                      cursor: "pointer",
                      textDecoration: "underline",
                      padding: "0",
                    }}
                  >
                    Import
                  </button>{" "}
                  to select the version again.&nbsp;&nbsp;
                </span>
              </span>
            )}
           {!useDxp ? "" : (
              isWebScreen && (
                <Link to="/myCart">
                  {localStorage.getItem("tgCartToken") && (
                    <span
                      className="my-cart-icon"
                      aria-hidden="true"
                      style={{ display: "inline" }}
                    >
                      <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                      {totalCartQuantity !== 0 && (
                        <span className="badge">{totalCartQuantity}</span>
                      )}
                    </span>
                  )}
                </Link>
              )
            )}
            <Profile authID={authID} handleLoginClick={handleLoginClick} />
            {(!authID.u || authID.u == " ") &&
              (!authID.i || authID.i === " ") && (
                <LoginButton handleLoginClick={handleLoginClick} handleAccessToken={handleAccessToken} handleDXPLoginClick={handleDXPLoginClick} />
              )}
            <LoginModal />
            </div>
          </div>
        )}
        <div
          id="navbar"
          className="navbar-collapse"
          style={{ maxHeight: "734px" }}
        >
          <div
            className="navbar-right menu-bar float-lg-none"
            data-weblinks-maxdepth="2"
            style={{ position: "relative", zIndex: 10 }}
          >
            <div className="container dart">
              <div className="row">
                <div className="col">
                  <ul className="nav navbar-nav weblinks" role="menubar">
                    <li
                      role="none"
                      className="visible-xs-block visible-sm-block visible-md-block"
                    >
                      <div
                        className="navbar-right loginsubbutton"
                        style={{ display: "none" }}
                      >
                        <div className="btn-group">
                          <a
                            href="#"
                            className="btn btn-info btn-block"
                            style={{ background: "#009965" }}
                          >
                            Access by OrgName
                          </a>
                        </div>
                      </div>
                    </li>
                    <li
                      role="none"
                      className={`weblink dropdown ${guidelineOpenClass}`}
                      id="guidelinesDropdown"
                    >
                      <NavLink
                        to="#"
                        role="menuitem"
                        aria-label="Guidelines"
                        className="dropdown-toggle dropbtn"
                        data-toggle="dropdown"
                        title="Guidelines"
                        aria-expanded="false"
                        aria-haspopup="true"
                        onClick={guidelinesDropdown}
                      >
                        Guidelines
                        <span className="caret"></span>
                      </NavLink>
                      <Menu toggleMobileMenu={toggleMobileMenu} push={push} />
                    </li>
                    <li role="none" className="weblink ">
                      <a
                        role="menuitem"
                        data-toggle="modal"
                        href="#drugIndexModal"
                        aria-label="Drugs"
                        title="Drugs"
                      >
                        Drugs
                      </a>
                    </li>
                    <li
                      role="none"
                      className={`weblink dropdown ${quicklinksOpenClass}`}
                      id="quicklinksDropdown"
                    >
                      <NavLink
                        to="#"
                        role="menuitem"
                        aria-label="Quick links"
                        className="dropdown-toggle dropbtn"
                        data-toggle="dropdown"
                        title="Quick links"
                        aria-expanded="false"
                        aria-haspopup="true"
                        onClick={quicklinksDropdown}
                      >
                        Quick links
                        <span className="caret"></span>
                      </NavLink>
                      <ul className="dropdown-menu" role="menu">
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Antibiotic prescribing in primary care - summary table"
                            href="https://ccmsfiles.tg.org.au/s6/PDFs/Antibiotic-Summary-table.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Antibiotic prescribing in primary care - summary table"
                          >
                            Antibiotic prescribing in primary care - summary
                            table
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Surgical prophylaxis - summary tables"
                            href="https://ccmsfiles.tg.org.au/s6/PDFs/ABG16-Surgical-antibiotic-prophylaxis-Collated-table-v4.pdf?type=Surgical%20prophylaxis%20-%20summary%20tables"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Surgical prophylaxis - summary tables"
                          >
                            Surgical prophylaxis - summary tables
                          </a>
                        </li>
                        <li role="none">
                          <HashLink
                            role="menuitem"
                            aria-label="Patient information and useful PDFs"
                            to="/patientInfo/"
                            title="Patient information and useful PDFs"
                          >
                            Patient information and useful PDFs
                          </HashLink>
                        </li>
                        <li role="none">
                          <HashLink
                            role="menuitem"
                            aria-label="Pregnancy and breastfeeding"
                            to="/viewTopic?guidelinePage=quicklinks&etgAccess=true"
                          >
                            Pregnancy and breastfeeding
                          </HashLink>
                        </li>
                        {(authID.i || authID.u) && (
                          <li role="none">
                            <HashLink
                              role="menuitem"
                              aria-label="Calculators"
                              to="/calculator/ccbwcalc"
                              title="Calculators"
                            >
                              Calculators
                            </HashLink>
                          </li>
                        )}
                        <li role="none">
                          <HashLink
                            role="menuitem"
                            aria-label="Tables and figures"
                            to="/tables-and-figures/"
                            title="Tables and figures"
                          >
                            Tables and figures
                          </HashLink>
                        </li>
                      </ul>
                    </li>
                    <li role="none" className="weblink ">
                      <NavLink
                        to="#"
                        role="menuitem"
                        data-toggle="modal"
                        aria-label="Favourites"
                        data-target="#myFavouritesModal"
                        onClick={openModal}
                      >
                        Favourites
                      </NavLink>
                    </li>
                    <li role="none" className="weblink ">
                      <HashLink
                        role="menuitem"
                        aria-label="Saved Searches"
                        to="/searchAction/"
                        title="Saved Searches"
                      >
                        Saved Searches
                      </HashLink>
                    </li>
                    <li role="none" className="offline-bar">
                    <ProgressBar />
                    </li>
                    <li className="nav-bar-search" role="none">
                   
                      <div id="searchForm" className="searchMobileHidden" ref={dropdownRef}>
                        <div className="input-group search-widget">
                          <div className="form-group" style={styles.inputWrapper} >
                            <form onSubmit={(e) => {
                              e.preventDefault();
                            }}>
                              <input
                                type="text"
                                id="searchString"
                                name="appendedinputbuttons"
                                className="form-control"
                                placeholder="Search"
                                autoComplete="off"
                                onKeyUp={(e) =>
                                  keyupSearchHandler(
                                    e,
                                    setWithinSearchVal
                                  )
                                }
                                onMouseUp={(e) =>
                                  keyupSearchHandler(
                                    e,
                                    setWithinSearchVal
                                  )
                                }
                                onFocus={() => setIsOpen(true)}
                                onChange={(e) => {
                                  dispatch(setSearchIndex(e.target.value))
                                  setIsOpen(true)
                                }}
                                value={searchIndex}
                                ref={inputFieldRef}
                              />
                            </form>

                          </div>
                          <span className="input-group-btn">
                            <a
                              data-testid="global-search-button"
                              className="btn nopadding btnsearchHeader"
                              onClick={() => handleSearchClick()}
                            >
                              <i className="flaticon-magnifying-glass34"></i>
                            </a>
                          </span>
                        </div>
                        {isOpen && searchIndex.length > 2 && <SearchDropdown items={items} handleSelect={handleSelect} type="search" keyword={searchIndex} />}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModalFlag && (
        <FavouriteModal openModalFlag={openModalFlag} closeModal={closeModal} />
      )}
      <div className="container tg-mobile-search">
        <div
          id="navbar"
          className="navbar-collapse"
          style={{ maxHeight: "734px" }}
        >
          <div
            className="navbar-right menu-bar float-lg-none "
            data-weblinks-maxdepth="2"
          >
            <ul className="nav navbar-nav weblinks" role="menubar">
              <li role="none">
                <div id="searchForm" className="searchMobileVisible" ref={dropdownMobileRef}>
                  <div className="input-group search-widget">
                    <div className="form-group">
                      <form onSubmit={(e) => {
                        e.preventDefault();
                      }}>
                        <input
                          type="search"
                          name="appendedinputbuttons"
                          id="searchStringMobile"
                          className="form-control"
                          placeholder="Search"
                          autoComplete="off"
                          onFocus={() => setIsOpen(true)}
                          onKeyUp={(e) =>
                            keyupSearchHandler(
                              e,
                              setWithinSearchVal,
                              true
                            )
                          }
                          onMouseUp={(e) =>
                            keyupSearchHandler(
                              e,
                              setWithinSearchVal,
                              true
                            )
                          }
                          onChange={(e) => {
                            dispatch(setSearchIndex(e.target.value))
                            setIsOpen(true)
                          }}
                          value={searchIndex}
                          ref={inputFieldRef}
                        />
                      </form>
                    </div>
                    <span className="input-group-btn">
                      <a
                        className="btn nopadding btnsearchHeader"
                        onClick={() => handleSearchClick("mobile")}
                      >
                        <i className="flaticon-magnifying-glass34"></i>
                      </a>
                    </span>
                  </div>

                  {isOpen && searchIndex.length > 2 && <SearchDropdown items={items} handleSelect={handleSelect} type="search" keyword={searchIndex} />}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <DrugIndexModal push={push} />
    </div>
  );
};

NavBar.propTypes = {
  searchIndex: PropTypes.number,
  setSearchTerm: PropTypes.func,
  push: PropTypes.func.isRequired,
  setWithinSearchVal: PropTypes.func,
  isDXP: PropTypes.bool,
};

export default NavBar;
