/* eslint-disable */

import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectMPSTopicFile,
  selectDitaPath,
  selectHeadTitle,
  selectTocId,
} from "../features/counter/counterSlice";
import { removeSpecialCharacters } from "../utils";

const Breadcrumb = React.lazy(() => import("./breadcrumb"));
const TopicTools = React.lazy(() => import("./topicTools"));

const TopicBanner = ({ pathname, search, push }) => {
  const [headerClass, setHeaderClass] = useState("");
  const mpstopicfile = useSelector(selectMPSTopicFile);
  const ditapath = useSelector(selectDitaPath);
  const headTitle = useSelector(selectHeadTitle);
  const tocId = useSelector(selectTocId);

  console.log("pathname", pathname);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    if (pathname.includes("searchAction") || pathname.includes("patientInfo")) {
      setHeaderClass("search-page");
    } else if (pathname.includes("/calculator/")) {
      setHeaderClass("calculator-page");
    } else if (pathname.includes("/userConfirmation")) {
      setHeaderClass("userConfirmation-page");
    } else if (pathname.includes("/myCart")) {
      setHeaderClass("myCart-page");
    } else if (pathname.includes("/checkout")) {
      setHeaderClass("checkout-page");
    } else if (pathname.includes("/showMyProfile")) {
      setHeaderClass("showMyProfile-page");
    } else if (
      pathname.includes("viewTopic") ||
      pathname.includes("topicTeaser")
    ) {
      const guidelinePage = searchParams.get("guidelinePage");
      const cleanDitapath = removeSpecialCharacters(ditapath);

      if (cleanDitapath === "") {
        return;
      }

      let cleanGuidelinePage = removeSpecialCharacters(guidelinePage);
      cleanGuidelinePage = cleanGuidelinePage
        ? cleanGuidelinePage.replace(/ /g, "_")
        : "";
      if (cleanGuidelinePage === "" || cleanDitapath === cleanGuidelinePage) {
        if (mpstopicfile[cleanDitapath]) {
          const className = mpstopicfile[cleanDitapath].bannerclass;
          setHeaderClass(className);
        } else {
          console.error(
            `No object found in mpstopicfile for ditapath: ${ditapath}`
          );
        }
      } else if (cleanDitapath !== cleanGuidelinePage) {
        const className =
          mpstopicfile[cleanGuidelinePage.replace("/", "")].bannerclass;
        setHeaderClass(className);
      } else {
        setHeaderClass("book-other");
      }
    }
  }, [ditapath, pathname, search]);

  const bannerText = useMemo(() => {
    const bannerMapping = {
      "/viewTopic/": headTitle || tocId,
      "/viewTopic": headTitle || tocId,
      "/searchAction/": "Search Results",
      "/calculator/": "Calculators",
      "/": "Therapeutic Guidelines",
      "/patientInfo/": "Patient information and useful PDFs",
      "/import/": "Import",
      "/myCart": "My Cart",
      "/userConfirmation": "User Confirmation",
      "/checkout": "Checkout",
      "/showMyProfile": "My account",
    };

    for (const [key, value] of Object.entries(bannerMapping)) {
      if (pathname === key || pathname.includes(key)) {
        return value;
      }
    }

  }, [pathname, headTitle, tocId]);

  return (
    <div id="root">
      <div className="page-heading">
        <div className="container">
          <Breadcrumb
            guideline={ditapath.replace(/_/g, " ").replace(/\//g, "")}
            push={push}
            topic={headTitle}
            pathname={pathname}
            mpstopicfile={mpstopicfile}
          />
        </div>
        <div className="viewTopic-banner">
          <input type="hidden" id="topicHighlight" value="" />
          <div className={`page-header2 ${headerClass}`}>
            <div className="container">
              <h1 id="topic-banner">{bannerText}</h1>
            </div>
          </div>
          {pathname.includes("viewTopic") && (
            <TopicTools locationSearch={search} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TopicBanner;